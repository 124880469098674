import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import getSymbolFromCurrency from 'currency-symbol-map';
import { businesses, tickets } from '../../../state';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '0 0 10px 0',
  },
})(MuiTableCell);

const ChargeBreakdown = () => {
  const { t } = useTranslation();

  const formData = useSelector(tickets.selectors.selectFormData);
  const business = useSelector(businesses.selectors.selectBusiness);
  const { currency } = business;
  const currencySymbol = getSymbolFromCurrency(currency || 'USD');

  const {
    totalAmount,
    feesAmount,
    taxAmount,
    discount,
    discountConfigAmount,
    discountType,
    tickets: boughtTickets,
  } = formData;

  return (
    <Grid item xs={12}>
      <Card raised>
        <CardContent>
          <Typography gutterBottom variant="body2">
            <b>{t('changeBreakdown')}</b>
          </Typography>
          <TableContainer>
            <Table aria-label="Charge breakdown">
              <TableBody>
                {boughtTickets?.map(
                  (ticket) =>
                    ticket.purchaseQuantity > 0 && (
                      <>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {ticket.ticketType?.name} {' x '}
                              {ticket.purchaseQuantity}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {t('cardValue', {
                              amount: ticket.amount,
                              formatParams: {
                                amount: { currency },
                              },
                            })}
                          </TableCell>
                        </TableRow>
                        {ticket.groupDiscountData ? (
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ paddingLeft: 25 }}
                            >
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                              >
                                Group Discount {ticket.groupDiscount} %
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              -
                              {t('cardValue', {
                                amount: ticket.groupDiscountData,
                                formatParams: {
                                  amount: { currency },
                                },
                              })}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}
                      </>
                    )
                )}

                {taxAmount > 0 && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">{t('ticket.tax')}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        {t('cardValue', {
                          amount: taxAmount,
                          formatParams: {
                            amount: { currency },
                          },
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {feesAmount > 0 && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">
                        {t('ticket.serviceFee')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        {t('cardValue', {
                          amount: feesAmount,
                          formatParams: {
                            amount: { currency },
                          },
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {discount > 0 && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">
                        {t('ticket.memberDiscount')} (
                        {discountType === 0
                          ? `${discountConfigAmount}%`
                          : `${discountConfigAmount}${currencySymbol}`}
                        )
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        -{' '}
                        {t('cardValue', {
                          amount: discount,
                          formatParams: {
                            amount: { currency },
                          },
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography variant="body2">
                      <b>{t('amountCharged')} </b>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <b>
                      {t('cardValue', {
                        amount: totalAmount,
                        formatParams: {
                          amount: { currency },
                        },
                      })}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ChargeBreakdown;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  Box,
  Button,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import QrScanner from 'react-qr-barcode-scanner';

import { Close } from '@material-ui/icons';
import { Cameraswitch } from '@mui/icons-material';
import { alert } from '../../state';

const GiftCardScanner = (props) => {
  const { close, open, setCode } = props;
  const [facingMode, setFacingMode] = useState('environment');
  const changeFacingMode = () =>
    setFacingMode(facingMode === 'environment' ? 'user' : 'environment');
  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Scan barcode / QR Code</DialogTitle>
      <DialogContent>
        <Box py={2}>
          {open && (
            <QrScanner
              key="environmentQR"
              facingMode={facingMode}
              onUpdate={(err, result) => {
                if (result?.text) {
                  const resultText = result.text.replace('sqgc://', '');
                  if (/^-?\d+$/.test(resultText)) {
                    setCode(resultText);
                  } else {
                    alert.actions.open({
                      message: 'Invalid QR code',
                      severity: 'error',
                    });
                  }
                }
              }}
              style={{ width: '100%' }}
            />
          )}
          <Box textAlign="center">
            <Button
              variant="outlined"
              onClick={changeFacingMode}
              style={{ padding: 10 }}
              textAlign="center"
              alignItems="center"
            >
              <Cameraswitch />
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={close}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};
GiftCardScanner.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setCode: PropTypes.func.isRequired,
};

export default GiftCardScanner;

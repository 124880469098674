import * as Yup from 'yup';
import i18n from 'i18next';

export const UserInfoSchema = (maxAmount, minAmount) =>
  Yup.object().shape({
    isGift: Yup.boolean(),
    customAmount: Yup.boolean(),
    giftEmail: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string().email('Invalid email').required('Required'),
      otherwise: Yup.string(),
    }),
    giftFirstName: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string()
        .min(2, i18n.t('tooShort'))
        .max(50, i18n.t('tooLong'))
        .required(i18n.t('required')),
      otherwise: Yup.string(),
    }),
    giftLastName: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string()
        .min(2, i18n.t('tooShort'))
        .max(50, i18n.t('tooLong'))
        .required(i18n.t('required')),
      otherwise: Yup.string(),
    }),
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    amount: Yup.number().when('customAmount', {
      is: true,
      then: Yup.number()
        .typeError('Amount must be a number')
        .min(minAmount, `Minumum amount $${minAmount}`)
        .max(maxAmount, `Maximum amount allowed is $${maxAmount}`)
        .required('Required'),
    }),
  });

export const CheckReloadSchema = (maxAmount, minAmount) =>
  Yup.object().shape({
    cardNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    amount: Yup.number()
      .typeError('Amount must be a number')
      .min(minAmount, `Minumum amount $${minAmount}`)
      .max(maxAmount, `Maximum amount allowed is $${maxAmount}`)
      .required('Required'),
  });

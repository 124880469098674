import React from 'react';
import {
  Grid,
  Typography,
  Box,
  MenuItem,
  Button,
  CircularProgress,
  FormHelperText,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  TableCell as MuiTableCell,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Formik } from 'formik';
import moment from 'moment/moment';
import { Stack } from '@mui/material';
import { CustomImgCard } from '../../custom';
import { alert, businesses, tickets } from '../../../state';
import UserInfoForm from './UserInfoForm';
import UserInfoSchema from './validation';
import api from '../../../state/tickets/api';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '0 0 10px 0',
  },
})(MuiTableCell);
// eslint-disable-next-line react/prop-types
const TicketInfo = ({ changeStep }) => {
  const business = useSelector(businesses.selectors.selectBusiness);
  const useStyles = makeStyles(() => ({
    fsBackground: {
      background: business?.brandColors?.backgroundColor || '#e4f9fc',
      minHeight: '170px',
      maxHeight: '170px',
      width: '100%',
      position: 'absolute',
      left: 0,
      zIndex: -1,
    },
    textColor: {
      '& *:not(.Mui-error):not(.sq-card-message-error):not(button):not(button span)': {
        color: business.brandColors?.textColor,
      },
    },
    fontGilmerBold: {
      fontFamily: 'gilmer-bold',
    },
    fontNarcost: {
      fontFamily: 'narcost-regular',
    },
    fontGilmer: {
      fontFamily: 'gilmer',
    },
    fontTicket: {
      fontSize: '0.95rem',
    },
  }));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const ticketEvent = useSelector(tickets.selectors.selectTicketEvent);
  const isLoading = useSelector(tickets.selectors.selectIsLoading);
  const { name, imageUrl, tagLine, startTime, title } = ticketEvent;
  const currency = business.currency || 'USD';

  const setFormData = (payload) =>
    dispatch(tickets.actions.setFormData(payload));

  const checkGroupDiscount = (
    groupDiscountQuantity,
    groupDiscountType,
    groupDiscount,
    purchaseQuantity,
    price
  ) => {
    if (groupDiscountQuantity && purchaseQuantity >= groupDiscountQuantity) {
      return groupDiscountType === 0
        ? (purchaseQuantity * price * groupDiscount) / 100
        : Math.min(purchaseQuantity * price, groupDiscount);
    }
    return 0;
  };

  return (
    <>
      {/* <div className={classes.fsBackground} /> */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          marginTop: 40,
        }}
        className={classes.textColor}
      >
        <Grid
          container
          item
          xs={12}
          md={9}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
          style={{
            backgroundColor: business?.brandColors?.formColor || '#eee',
          }}
        >
          <Grid item md={5} xs={12}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h3" className={classes.fontNarcost}>
                {title}
              </Typography>
              <Typography
                variant="h6"
                className={[classes.mb2, classes.fontGilmerBold]}
              >
                {tagLine}
              </Typography>
              <Typography
                variant="body1"
                className={[classes.mb2, classes.fontGilmer]}
              >
                {moment(`${startTime}Z`).format('ddd, MMM D, YYYY h:mm A')}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={7} xs={12}>
            <CustomImgCard
              isLoading={isLoading}
              name={name}
              imageUrl={imageUrl}
              square
            />
          </Grid>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize
        validationSchema={UserInfoSchema}
        initialValues={{
          businessId: ticketEvent.businessId || '',
          id: ticketEvent.id || '',
          email: '',
          firstName: '',
          lastName: '',
          tickets:
            ticketEvent.ticketPricing?.map((p) => ({
              ...p,
              ticketPricingId: p.id,
            })) || [],
          totalTicket: 0,
          tax: 0,
          fees: 0,
          taxAmount: 0,
          feesAmount: 0,
          totalAmount: 0,
          currency: currency || 'USD',
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          setFormData(values);
          const res = await api.calculateTicketCost(values);
          if (res) {
            setFormData({ ...res });
            changeStep(2);
          } else {
            openAlert({
              message: res.payload?.message || 'Something went wrong...',
              severity: 'error',
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleSubmit, values, setFieldValue, errors }) => {
          const handleChangeQuantity = (index, purchaseQuantity) => {
            const updatedTickets = [...values.tickets];
            updatedTickets[index].purchaseQuantity = purchaseQuantity;
            setFieldValue(
              `tickets[${index}].amount`,
              purchaseQuantity * values.tickets[index].price
            );
            setFieldValue(
              `tickets[${index}].purchaseQuantity`,
              purchaseQuantity
            );
            setFieldValue(`tickets[${index}].groupDiscountData`, 0);
            const groupDiscountData = checkGroupDiscount(
              values.tickets[index].groupDiscountQuantity,
              values.tickets[index].groupDiscountType,
              values.tickets[index].groupDiscount,
              purchaseQuantity,
              values.tickets[index].price
            );
            setFieldValue(
              `tickets[${index}].groupDiscountData`,
              groupDiscountData
            );

            const totalTicket = updatedTickets.reduce(
              (sum, ticket) => sum + (ticket.purchaseQuantity || 0),
              0
            );
            const ticketAmount = updatedTickets.reduce(
              (sum, ticket) =>
                sum +
                (ticket.purchaseQuantity * ticket.price || 0) -
                checkGroupDiscount(
                  ticket.groupDiscountQuantity,
                  ticket.groupDiscountType,
                  ticket.groupDiscount,
                  ticket.purchaseQuantity,
                  ticket.price
                ),
              0
            );
            const tax = (ticketAmount * ticketEvent.tax) / 100;
            const fee =
              ticketEvent.feeType === 0
                ? (ticketAmount * ticketEvent.fees) / 100
                : ticketEvent.fees;
            setFieldValue('taxAmount', tax.toFixed(2));
            setFieldValue('feesAmount', fee.toFixed(2));
            setFieldValue('totalAmount', (ticketAmount + tax + fee).toFixed(2));
            setFieldValue('totalTicket', totalTicket);
          };
          return (
            <>
              <Grid
                container
                justifyContent="center"
                className={[classes.fontGilmer, classes.textColor]}
              >
                <Grid item xs={12} md={9}>
                  <Box
                    style={{ border: '1px solid #ccc' }}
                    p={4}
                    mt={4}
                    className={classes.fontGilmer}
                  >
                    <Typography variant="h5" className={classes.fontGilmerBold}>
                      {t('ticket.tickets')}
                    </Typography>
                    <br />
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {ticketEvent?.ticketPricing?.map((pricing, index) => (
                            <>
                              <TableRow
                                key={pricing.id}
                                style={{ fontSize: 16 }}
                              >
                                <TableCell>
                                  <Typography
                                    variant="button"
                                    className={classes.fontTicket}
                                  >
                                    {pricing.ticketType?.name}
                                  </Typography>
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                  <Typography
                                    variant="button"
                                    className={classes.fontTicket}
                                  >
                                    {t('cardValue', {
                                      amount: pricing.price,
                                      formatParams: {
                                        amount: { currency },
                                      },
                                    })}
                                  </Typography>
                                </TableCell>
                                <TableCell width={20} align="right">
                                  {pricing?.quantity !== 0 && 'x'}
                                </TableCell>
                                <TableCell width={80} align="right">
                                  {pricing?.quantity !== 0 && (
                                    <TextField
                                      className={classes.fontTicket}
                                      select
                                      value={
                                        values.tickets[index]
                                          ?.purchaseQuantity || ''
                                      }
                                      onChange={(event) => {
                                        setFieldValue(
                                          `tickets[${index}].name`,
                                          pricing.ticketType?.name
                                        );
                                        handleChangeQuantity(
                                          index,
                                          Number(event.target.value)
                                        );
                                      }}
                                      variant="outlined"
                                      style={{ fontSize: 16 }}
                                    >
                                      {Array.from(
                                        {
                                          length:
                                            Math.min(
                                              pricing?.quantity || 0,
                                              pricing?.maxPerOrder || 0
                                            ) -
                                            (pricing?.minPerOrder || 0) +
                                            1,
                                        },
                                        (_, i) =>
                                          (pricing?.minPerOrder || 0) + i
                                      ).map((i) => (
                                        <MenuItem
                                          key={i}
                                          value={i}
                                          className={classes.fontTicket}
                                        >
                                          {i}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  )}
                                </TableCell>
                                <TableCell
                                  width={20}
                                  align="right"
                                  className={classes.fontTicket}
                                >
                                  {pricing?.quantity !== 0 && '='}
                                </TableCell>
                                <TableCell
                                  width={150}
                                  align="right"
                                  className={classes.fontTicket}
                                >
                                  {pricing?.quantity !== 0 ? (
                                    t('cardValue', {
                                      amount:
                                        values.tickets?.[index]?.amount || 0,
                                      formatParams: {
                                        amount: {
                                          currency,
                                        },
                                      },
                                    })
                                  ) : (
                                    <FormHelperText error>
                                      <Typography
                                        variant="body2"
                                        className={[
                                          classes.fontTicket,
                                          classes.fontGilmerBold,
                                        ]}
                                        style={{ textAlign: 'right' }}
                                      >
                                        {' '}
                                        Sold Out
                                      </Typography>
                                    </FormHelperText>
                                  )}
                                </TableCell>
                              </TableRow>
                              {values.tickets?.[index]?.groupDiscountData ? (
                                <TableRow
                                  key={`${pricing.id}-group-discount`}
                                  style={{ fontSize: 16 }}
                                >
                                  <TableCell style={{ paddingLeft: 40 }}>
                                    <Typography
                                      variant="h6"
                                      className={classes.fontTicket}
                                    >
                                      Group Discount{' '}
                                      {values.tickets?.[index]?.groupDiscount} %
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    colSpan={5}
                                    align="right"
                                    className={classes.fontTicket}
                                  >
                                    -
                                    {t('cardValue', {
                                      amount:
                                        values.tickets[index].groupDiscountData,
                                      formatParams: {
                                        amount: {
                                          currency,
                                        },
                                      },
                                    })}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                ''
                              )}
                            </>
                          ))}

                          <TableRow height={20} />
                          {values.taxAmount > 0 && (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <Typography
                                  variant="button"
                                  className={classes.fontTicket}
                                >
                                  {t('ticket.tax')}
                                </Typography>
                              </TableCell>
                              <TableCell width={20} align="right" />
                              <TableCell width={80} align="right" />
                              <TableCell width={20} align="right" />
                              <TableCell width={150} align="right">
                                <Typography
                                  variant="button"
                                  className={classes.fontTicket}
                                >
                                  {t('cardValue', {
                                    amount: values.taxAmount,
                                    formatParams: {
                                      amount: { currency },
                                    },
                                  })}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {values.feesAmount > 0 && (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <Typography
                                  variant="button"
                                  className={classes.fontTicket}
                                >
                                  {t('ticket.serviceFee')}
                                </Typography>
                              </TableCell>
                              <TableCell width={20} align="right" />
                              <TableCell width={80} align="right" />
                              <TableCell width={20} align="right" />
                              <TableCell width={150} align="right">
                                <Typography
                                  variant="button"
                                  className={classes.fontTicket}
                                >
                                  {t('cardValue', {
                                    amount: values.feesAmount,
                                    formatParams: {
                                      amount: { currency },
                                    },
                                  })}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              style={{ fontWeight: '700' }}
                            >
                              <Typography
                                variant="button"
                                className={classes.fontTicket}
                              >
                                {t('ticket.total')}
                              </Typography>
                            </TableCell>
                            <TableCell width={20} align="right" />
                            <TableCell width={80} align="right" />
                            <TableCell width={20} align="right" />
                            <TableCell
                              width={150}
                              align="right"
                              style={{ fontWeight: '700' }}
                              className={classes.fontTicket}
                            >
                              {t('cardValue', {
                                amount: values.totalAmount,
                                formatParams: {
                                  amount: { currency },
                                },
                              })}
                            </TableCell>
                          </TableRow>
                          {errors.totalTicket && (
                            <TableRow>
                              <TableCell colSpan={6}>
                                <FormHelperText error>
                                  <Typography variant="body2">
                                    {' '}
                                    {errors.totalTicket}
                                  </Typography>
                                </FormHelperText>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                className={classes.textColor}
              >
                <Grid item xs={12} md={9}>
                  <UserInfoForm changeStep={changeStep} />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    size="large"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('continue')
                    )}
                  </Button>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default TicketInfo;
